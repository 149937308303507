



























































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import NewSubscriberForm from '@/components/forms/NewSubscriberForm.vue';
import ConfirmationModal from '@/components/modals/ConfirmationModal.vue';

@Component({
  components: {
    NewSubscriberForm,
    ConfirmationModal,
  },
})
export default class NewSubscriberModal extends Vue {
  @Prop() public opened!: boolean;

  public isOpen = false;

  public isCloseConfirmationOpen = false;

  public isAnyInputFilled = false;

  public clearForm = false;

  public created() {
    this.$bus.$on('close-new-subscriber-form-modal', () => {
      this.isOpen = false;

      this.$emit('close-modal');

      this.clearForm = true;
    });
  }

  public closeModal() {
    if (!this.isAnyInputFilled) {
      this.isOpen = false;

      this.$emit('close-modal');

      return;
    }

    this.isCloseConfirmationOpen = true;
  }

  public openModal() {
    this.clearForm = false;

    this.isOpen = true;
  }

  public confirmClosing() {
    this.isCloseConfirmationOpen = false;
    this.isOpen = false;

    this.$emit('close-modal');

    this.clearForm = true;
  }

  public discardClosing() {
    this.isCloseConfirmationOpen = false;
  }

  public saveInputsState(inputsState: boolean) {
    this.isAnyInputFilled = inputsState;
  }

  @Watch('opened')
  public onOpenedChange() {
    this.isOpen = this.opened;
  }
}
