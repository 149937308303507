













































































import {
  Component, Vue, Watch, Prop,
} from 'vue-property-decorator';
import Auth from '@/helpers/Auth';
import AuthApi from '@/api/Auth';
import { TranslateResult, LocaleMessages } from 'vue-i18n';
// @ts-ignore
import { NativeEventSource, EventSourcePolyfill } from 'event-source-polyfill';
import config from '@/config';
import Api from '@/api/Api';

const EventSource = EventSourcePolyfill || NativeEventSource;

@Component
export default class AppHeader extends Vue {
  public isOpen = false;

  public activeItem: null | number = null;

  public currentRouteName = '';

  public balance: string | TranslateResult | LocaleMessages | null = null;

  public created() {
    this.setCurrentRouteName();
    this.detectInitialActiveItem();
    this.getBalance();
  }

    public async refreshToken() {
    const isRefreshed = await Api.$instance.refreshToken();

    if (isRefreshed) {
      this.getBalance();
    }
  }

  public getBalance() {
    const stream = new EventSource(`${config.api.baseUrl}/user/balance`, {
      headers: {
        'Authorization': `Bearer ${this.$store.getters['auth/accessToken']}`
      }
    });
    // @ts-ignore
    stream.addEventListener('error', async (e) => {
      if (e.status === 401) {
        await this.refreshToken()
      }
    })
    //@ts-ignore
    stream.addEventListener('balance', balance => {
      // @ts-ignore
      const response = JSON.parse(balance.data);
      response.balance ? this.balance = `${this.$t('header.balance')}${response.balance} ${this.$t('general.currency')}` : null;
    })
  }

  public detectInitialActiveItem() {
    switch (this.$route.name) {
      case 'home':
        this.activeItem = 0;

        break;
      case 'logs':
        this.activeItem = 1;
        break;
      default:
        this.activeItem = null;
        break;
    }
  }

  public setCurrentRouteName() {
    // @ts-ignore
    this.currentRouteName = this.$t(`routes.${this.$route.name}`);
  }

  public async logout() {
    const response = await AuthApi.logout();

    if (response.status >= 300) {
      this.$bus.$emit('show-notification', {
        text: this.$t('snackbarNotifications.smthWentWrong'),
        color: 'error',
      });

      return;
    }

    Auth.invalidateAccessToken();

    this.$router.push({ name: 'login' });
  }

  @Watch('$route')
  public routeChanged() {
    this.setCurrentRouteName();
  }
}
