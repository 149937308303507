import Schedule from '@/models/Schedule';
import Subscriber from '@/models/Subscriber';
import Model from '@/models/Model';

export default class Log extends Model {
  public id!: number;

  public type!: string;

  public balance!: string;

  public cost!: string;

  public error_message!: string;

  public createdAt!: string;

  public schedules!: Schedule[];

  public subscribers!: Subscriber[];
}
