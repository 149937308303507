
























import { Component, Mixins, Watch } from 'vue-property-decorator';
import LogsFilter from '@/api/LogsFilter';
import { Log as LogInterface, FormattedLog as FormattedLogInterface } from '@/config/types';
import { DateTime } from 'luxon';
// @ts-ignore
import { NativeEventSource, EventSourcePolyfill } from 'event-source-polyfill';
import config from '@/config';
import Phone from '@/config/mixins/Phone';
import Api from '@/api/Api';
import Auth from '@/helpers/Auth';

const EventSource = EventSourcePolyfill || NativeEventSource;

@Component
export default class Logs extends Mixins(Phone) {
  public options: any = {};
  public loading: boolean = true;
  public logs: FormattedLogInterface[] = [];
  public totalLogs: number = 0;

  public defaultItemsPerPage: number = 10;
  public defaultPage: number = 1;

  public page: number = 1;
  public rows: number = 5;

  public stream: any = null;

  public headers: any = [
    {
      text: this.$t('logsHeaders.subscriber'),
      align: 'start',
      sortable: false,
      value: 'subscriber',
    },
    {
      text: this.$t('logsHeaders.phone'),
      value: 'phone',
      sortable: false,
    },
    {
      text: this.$t('logsHeaders.message'),
      value: 'message',
      sortable: false,
    },
    {
      text: this.$t('logsHeaders.status'),
      value: 'status',
      sortable: false,
    },
    {
      text: this.$t('logsHeaders.time'),
      value: 'time',
      sortable: false,
    },
    {
      text: this.$t('logsHeaders.cost'),
      value: 'cost',
      sortable: false,
    },
    {
      text: this.$t('logsHeaders.balance'),
      value: 'balance',
      sortable: false,
    },
  ];

  async created() {
    if (this.$route.query.rows) {
      // @ts-ignore
      this.defaultItemsPerPage = parseInt(this.$route.query.rows);
    }

    if (this.$route.query.page) {
      // @ts-ignore
      this.defaultPage = parseInt(this.$route.query.page);
    }
    this.loadData();
  }

  public rowClasses(item: any) {
    return item.color === 'error' ? 'red lighten-5' : 'green lighten-5'
  }

  public formatLog(log: LogInterface) {
    let status = this.$t('logsStatus.success');
    let color = 'success';
    let cost = `${log.cost} ${this.$t('general.currency')}`
    let balance = `${log.balance} ${this.$t('general.currency')}`

    if (log.error_message) {
      status = `${this.$t('logsStatus.error')}${log.error_message}`;
      color = 'error';
      cost = `0 ${this.$t('general.currency')}`;
    }

    if (!log.balance) {
      // @ts-ignore
      balance = '-'
    }

    const time = DateTime.fromISO(log.createdAt)
      .setLocale(this.$i18n.locale)
      .toLocaleString(DateTime.DATETIME_SHORT);
    return {
      // @ts-ignore
      subscriber: log.extraData.subscriber.name,
      // @ts-ignore
      phone: this.formatPhone(log.extraData.subscriber.phone),
      // @ts-ignore
      message: log.extraData.schedule.message,
      status,
      cost,
      balance,
      color,
      time,
    }
  }

  public async refreshToken() {
    const isRefreshed = await Api.$instance.refreshToken();

    if (isRefreshed) {
      this.loadData();
    }
  }

  public loadData() {
    this.loading = true;

    if (this.stream) {
      this.stream.close();
    }

    this.stream = new EventSource(`${config.api.baseUrl}/logs/?page=${this.page}&rows=${this.rows}`, { headers: {
        'Authorization': `Bearer ${this.$store.getters['auth/accessToken']}`
      } });
    // @ts-ignore
    this.stream.addEventListener('error', async (e) => {
      if (e.status === 401) {
        await this.refreshToken()
      }
    })
    // @ts-ignore
    this.stream.addEventListener('logsQuantity', logsQuantity => {
      const response = JSON.parse(logsQuantity.data);
      this.totalLogs = response;
    })
    // @ts-ignore
    this.stream.addEventListener('log', log => {
      this.loading = false;
      let response = JSON.parse(log.data);

      const formattedLogs: FormattedLogInterface[] = [];

      const logs = response.data.map((log: any) => {
        return LogsFilter.filterIncluded(log, response.included);
      });

      logs.forEach((log: any) => {
        const formattedLog = this.formatLog(log);
        formattedLogs.push(formattedLog);
      })

      this.logs = formattedLogs;
    })
  }

  public destroyed() {
    this.stream.close();
  }

  @Watch('options', { immediate: true, deep: true })
  onOptionsChange(newVal: any) {
    const { page, itemsPerPage } = this.options;
    this.page = page;
    this.rows = itemsPerPage;

    this.$nextTick(() => {
      this.loadData();
    })
  }

  @Watch('page')
  onPageChange() {
    // @ts-ignore
    this.$router.replace({query: { page: this.page, rows: this.rows }}).catch(err => {})
  }

  @Watch('rows')
  onRowsChange() {
    // @ts-ignore
    this.$router.replace({query: { page: this.page, rows: this.rows }}).catch(err => {})
  }
}
