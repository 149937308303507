



















































































































import {
  Component, Watch, Mixins, Prop,
} from 'vue-property-decorator';
import {max, required} from 'vee-validate/dist/rules';
import {
  extend, ValidationObserver, ValidationProvider, setInteractionMode,
} from 'vee-validate';
import Phone from '@/config/mixins/Phone';
import { SubscriberCredentials } from '@/config/types';
import SubscriberApi from '@/api/Subscribers';
import ServerErrors from '@/config/mixins/ServerErrors';
import ConfirmationModal from '@/components/modals/ConfirmationModal.vue';

setInteractionMode('eager');

extend('required', {
  ...required,
  message: 'Это поле должно быть заполнено',
});

extend('max', {
  ...max,
  message: 'Длинна сообщения должна быть не более {length} символов',
});

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    ConfirmationModal,
  },
})
export default class EditSubscriberForm extends Mixins(Phone, ServerErrors) {
  @Prop() public name?: string;

  @Prop() public phone!: string;

  @Prop() public subscriberId!: number;

  @Prop() public clearForm!: boolean;

  public isClearConfirmationOpen: boolean = false;

  public isFormLoading = false;

  public nameMaxLength = 160;

  public isAnyInputChanged = false;

  public isDeleteConfirmationOpen = false;

  public updatedSubscriber = {
    // @ts-ignore
    name: this.name || '',
    phone: this.formatPhone(this.phone),
  }

  public showClearConfirmationModal() {
    this.isClearConfirmationOpen = true;
  }

  public confirmClear() {
    this.resetAllInputs();

    this.isClearConfirmationOpen = false;
  }

  public discardClear() {
    this.isClearConfirmationOpen = false;
  }

  public async submit() {
    this.isFormLoading = true;
    // @ts-ignore
    const isValid = await this.$refs.observer.validate();

    if (!isValid) {
      this.isFormLoading = false;

      return;
    }

    const subscriberCredentials: SubscriberCredentials = { ...this.updatedSubscriber };

    subscriberCredentials.phone = this.makePhoneValid(subscriberCredentials.phone);

    const subscriberResponse = await SubscriberApi.updateSubscriber(subscriberCredentials, this.subscriberId);

    if (subscriberResponse.status >= 500) {
      return;
    }

    if (subscriberResponse.status >= 300) {
      this.parseServerErrors(subscriberResponse.data.errors, this.$refs.observer);

      this.isFormLoading = false;

      return;
    }

    this.isFormLoading = false;

    this.$bus.$emit('subscriber-updated');
  }

  public async confirmDelete() {
    const response = await SubscriberApi.deleteSubscriber(this.subscriberId);

    if (response.status >= 500) {
      return;
    }

    if (response.status >= 300) {
      this.isDeleteConfirmationOpen = false;

      this.$bus.$emit('show-notification', {
        text: this.$t('snackbarNotifications.smthWentWrong'),
        color: 'error',
      });

      return;
    }

    this.isDeleteConfirmationOpen = false;

    this.$bus.$emit('subscriber-deleted');
  }

  public discardDelete() {
    this.isDeleteConfirmationOpen = false;
  }

  public async deleteSubscriber() {
    this.isDeleteConfirmationOpen = true;
  }

  public async closeFormModal() {
    if (!this.isAnyInputChanged) {
      this.$emit('close-form-modal');

      return;
    }

    this.$emit('show-close-confirmation');
  }

  public resetAllInputs() {
    this.updatedSubscriber = {
      // @ts-ignore
      name: this.name || '',
      phone: this.formatPhone(this.phone),
    };
    // @ts-ignore
    this.$refs.observer.reset();
    this.isAnyInputChanged = false;
  }

  checkIfAnyInputChanged() {
    let isNameInputChanged = false;
    let isPhoneInpuChanged = false;

    if (this.name && this.updatedSubscriber.name !== this.name) {
      isNameInputChanged = true;
    }

    if (!this.name && this.updatedSubscriber.name !== '') {
      isNameInputChanged = true;
    }

    if (this.makePhoneValid(this.updatedSubscriber.phone) !== this.phone) {
      isPhoneInpuChanged = true;
    }

    if (isNameInputChanged || isPhoneInpuChanged) {
      this.isAnyInputChanged = true;
    } else {
      this.isAnyInputChanged = false;
    }
  }

  @Watch('updatedSubscriber.name')
  public updatedSubscriberNameChanged() {
    this.checkIfAnyInputChanged();
  }

  @Watch('updatedSubscriber.phone')
  public updatedSubscriberPhoneChanged() {
    this.checkIfAnyInputChanged();
  }

  @Watch('clearForm')
  public clearFormPropChanged() {
    if (this.clearForm) {
      this.resetAllInputs();
    }
  }
}
