





























































































































import { Component, Mixins } from 'vue-property-decorator';
// @ts-ignore
import { NativeEventSource, EventSourcePolyfill } from 'event-source-polyfill';
import Time from '@/config/mixins/Time';
import config from '@/config';
import Phone from '@/config/mixins/Phone';
import Api from '@/api/Api';
import Auth from '@/helpers/Auth';

const EventSource = EventSourcePolyfill || NativeEventSource;

@Component
export default class Archive extends Mixins(Time, Phone) {
  public stream: any = null;
  public schedules: any = [];

  public loading: boolean = true;

  async created() {
    this.loadData();
  }

  public formattedPhone(phone: string) {
    return this.formatPhone(phone);
  }

  public formatSchedule(schedule: any) {
    let success = false;
    let errorMessage = null;

    if (schedule.log && !schedule.log.error_message) {
      success = true;
    }

    if (schedule.log && schedule.log.error_message) {
      errorMessage = schedule.log.error_message;
    }
    return {
      name: schedule.subscriber.name,
      phone: schedule.subscriber.phone,
      message: schedule.message,
      repeatFrequency: schedule.repeat_frequency,
      time: this.joinTime(schedule.hour, schedule.minute),
      startDate: schedule.start_date,
      endDate: schedule.end_date,
      success,
      errorMessage,
    }
  }

  public async refreshToken() {
    const isRefreshed = await Api.$instance.refreshToken();

    if (isRefreshed) {
      this.loadData();
    }
  }

  public loadData() {
    this.loading = true;

    this.stream = new EventSource(`${config.api.baseUrl}/schedules/archive`, { headers: {
        'Authorization': `Bearer ${this.$store.getters['auth/accessToken']}`
      } });

    // @ts-ignore
    this.stream.addEventListener('error', async (e) => {
      if (e.status === 401) {
        await this.refreshToken()
      }
    })

    // @ts-ignore
    this.stream.addEventListener('archive', archive => {
      this.loading = false;

      const response = JSON.parse(archive.data);

      this.schedules = response.data.map((schedule: any) => {
        return this.formatSchedule(schedule.attributes);
      });
    })
  }

  public destroyed() {
    this.stream.close();
  }
}
