/* eslint-disable  @typescript-eslint/no-explicit-any */
import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import SubscriberApi from '@/api/Subscribers';

const actions: ActionTree<any, RootState> = {
  async loadSubscribers({ commit }, q?: string) {
    const subscribers = await SubscriberApi.getAllSubscribers(q);

    commit('setSubscribers', subscribers);
  },
};

export default actions;
