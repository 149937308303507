/* eslint-disable no-prototype-builtins */
/* eslint-disable  @typescript-eslint/no-explicit-any */

import Axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { UrlParams } from '@/api/index';
import Auth from '@/helpers/Auth';
import config from '@/config';
import router from '@/router';

export default class Api {
  private static instance: Api;

  protected client: AxiosInstance;

  protected refreshUrl = '/auth/refresh';
  protected logoutUrl = '/auth/logout';

  protected headers: any = {
    Accept: 'application/json',
    'X-Requested-With': 'XMLHTTPRequest',
  };

  private constructor() {
    this.client = this.initClient();
  }

  public setHeaders(headers: object): Api {
    this.headers = headers;

    this.initClient();

    return this;
  }

  public setHeader(header: string, value: string): Api {
    this.headers = {
      ...this.getHeaders(),
      ...{ header: value },
    };

    this.initClient();

    return this;
  }

  public getHeaders(): object {
    return this.headers;
  }

  public getHeader(header: string): string {
    // @ts-ignore
    return this.headers.hasOwnProperty(header) ? this.headers[header] : null;
  }

  public setAuthToken(token: string): Api {
    if (token) {
      this.headers = {
        ...this.getHeaders(),
        ...{
          Authorization: `Bearer ${token}`,
        },
      };
    } else if (this.headers.hasOwnProperty('Authorization')) {
      delete this.headers.Authorization;
    }

    return this;
  }

  public async refreshToken(): Promise<any> {
    try {
      const response = await this.post(this.refreshUrl);

      Auth.setAccessToken(response.data.accessToken);

      return true;
    } catch (e) {
      await this.post(this.logoutUrl);

      Auth.invalidateAccessToken();

      router.push({name: 'login'});

      return false;
    }
  }

  public async request(url: string, method: string, payload?: any) {
    try {
      if (payload) {
        // @ts-ignore
        return await Api.$instance[method](url, payload);
      }
      // @ts-ignore
      return await Api.$instance[method](url);
    } catch (e) {
      if (e.response.status >= 500) {
        await router.push({name: 'error500'});
      }

      throw e;
    }
  }

  public async safeRequest(url: string, method: string, payload?: any) {
    try {
      if (payload) {
        return await Api.$instance.request(url, method, payload);
      }

      return await Api.$instance.request(url, method);
    } catch (e) {
      if (e.response.status === 401) {
        const isRefreshed = await Api.$instance.refreshToken();

        if (isRefreshed) {
          if (payload) {
            return await Api.$instance.request(url, method, payload);
          }

          return await Api.$instance.request(url, method);
        }
      }

      return e.response;
    }
  }

  public async get(url: string, config?: AxiosRequestConfig): Promise<any> {
    this.initClient();
    return await this.client.get(url, config);
  }

  public async delete(url: string, config?: AxiosRequestConfig): Promise<any> {
    this.initClient();
    return await this.client.delete(url, config);
  }

  public async post(url: string, data?: any, config?: AxiosRequestConfig): Promise<any> {
    this.initClient();
    return await this.client.post(url, data, config);
  }

  public async put(url: string, data?: any, config?: AxiosRequestConfig): Promise<any> {
    this.initClient();
    return await this.client.put(url, data, config);
  }

  public static get $instance() {
    if (!Api.instance) {
      Api.instance = new Api();
    }

    return Api.instance;
  }

  protected initClient() {
    this.client = Axios.create({
      baseURL: config.api.baseUrl,
      headers: this.getHeaders(),
    });

    return this.client;
  }

  public static url(url: string, params?: UrlParams) {
    if (!params) {
      return url;
    }

    for (const param in params) {
      if (params.hasOwnProperty(param)) {
        url = url.replace(new RegExp(':' + param, 'g'), String(params[param]));
      }
    }

    return url;
  }
}
