/* eslint-disable  @typescript-eslint/no-explicit-any */
import { Module } from 'vuex';
import state from './state';
import getters from './getters';
import actions from './actions';
import mutations from './mutations';
import { RootState } from '../../types';

const subscribers: Module<any, RootState> = {
  state,
  actions,
  mutations,
  getters,
};

export default subscribers;
