export default {
  ru: {
    homepage: {
      noSubscribers: 'У Вас нет еще ни одного получателя. Нажмите кнопку ниже чтобы добавить адресата!',
      noSubscribersFound: 'Пользователя с такими данными не найдено. Проверьте и попробуйте снова.',
    },
    logs: {
      noLogs: 'У Вас еще нет истории, так как не было ни одной отправки.',
    },
    archive: {
      noArchive: 'Архив пока пуст.',
    },
    accordion: {
      noSchedules: 'У этого пользователя пока нет расписаний.'
    },
    routes: {
      home: 'Главная',
      logs: 'История',
      archive: 'Архив',
    },
    archiveCard: {
      listTitles: {
        message: 'Текст сообщения:',
        startDate: 'Дата начала рассылки:',
        endDate: 'Дата окончания рассылки:',
        time: 'Время отправки:',
        repeatFrequency: 'Частота повторения:',
      },
    },
    btns: {
      addSchedule: 'Добавить новое расписание',
      editSubscriber: 'Редактировать получателя',
      save: 'Сохранить',
      discardChanges: 'Отменить изменения',
      deleteSchedule: 'Удалить расписание',
      close: 'Закрыть',
      deleteSubscriber: 'Удалить получателя',
      clearAll: 'Очистить все',
      discard: 'Отмена',
      confirm: 'Подтвердить',
      login: 'Войти',
      logout: 'Выйти',
      addSubscriber: 'добавить нового получателя',
    },
    newSubscriberModal: {
      header: 'Новый получатель',
    },
    newScheduleModal: {
      header: 'Новое расписание',
    },
    editSubscriberModal: {
      header: 'Редактировать получателя',
    },
    editScheduleModal: {
      header: 'Редактировать расписание',
    },
    confirmationModals: {
      closeNewSubscriberTitle: 'Вы уверены, что хотите закрыть окно этого получателя?',
      closeNewSubscriberText: 'Информация, которую Вы ввели не будет сохранена',
      closeNewScheduleTitle: 'Вы уверены, что хотите закрыть окно этого расписания?',
      closeNewScheduleText: 'Информация, которую Вы ввели не будет сохранена',
      closeEditSubscriberTitle: 'Вы уверены, что хотите закрыть окно этого получателя?',
      closeEditSubscriberText: 'Информация, которую Вы ввели не будет сохранена',
      deleteSubscriberTitle: 'Вы уверены, что хотите удалить этого пользователя?',
      deleteSubscriberText: 'Вы удалите его данные из списка рассылки и он не будет более получать сообщений',
      deleteScheduleTitle: 'Вы уверены, что хотите удалить это расписание?',
      deleteScheduleText: 'После удаления адресат больше не будет получать эту рассылку',
      closeEditScheduleTitle: 'Вы уверены, что хотите закрыть окно этого расписания?',
      closeEditScheduleText: 'Информация, которую Вы ввели не будет сохранена',
      clearInputsTitle: 'Вы уверены, что хотите очистить все поля?',
      clearInputsText: 'Введенная информация не будет сохранена',
      resetInputsTitle: 'Вы уверены, что хотите отменить все изменения?',
      resetInputsText: 'Введенная информация не будет сохранена',
    },
    snackbarNotifications: {
      newSubscriberAdded: 'Новый получатель успешно добавлен',
      newScheduleAdded: 'Новое расписание успешно добавлено',
      subscriberUpdated: 'Данные получателя успешно обновлены',
      subscriberDeleted: 'Получатель успешно удален',
      smthWentWrong: 'Упс! Что-то пошло не так. Перегрузите страницу и попробуйте снова',
      scheduleDeleted: 'Расписание получателя успешно удалено',
      scheduleUpdated: 'Расписание получателя успешно обновлено',
    },
    loginForm: {
      phoneLabel: 'Телефон',
      passwordLabel: 'Пароль',
    },
    scheduleForm: {
      messageLabel: 'Текст сообщения',
      startDateLabel: 'Дата начала рассылки',
      endDateLabel: 'Дата окончания рассылки',
      repeatFrequencyLabel: 'Как часто повторять',
      timeLabel: 'В котором часу отправлять',
      repeatFrequencyTooltip: 'Введите данные в формате 1d = ежедневно; 3d = каждые три дня; 1w 2d = каждые 9 дней; 1m = каждый месяц',
    },
    subscriberForm: {
      nameLabel: 'Имя получателя',
      phoneLabel: 'Телефон',
    },
    schedulesTable: {
      messageColumnHeader: 'Текст сообщения',
      startDateColumnHeader: 'Дата начала рассылки',
      endDateColumnHeader: 'Дата окончания рассылки',
    },
    header: {
      balance: 'Баланс: ',
    },
    logsHeaders: {
      subscriber: 'Получатель',
      phone: 'Телефон',
      message: 'Сообщение',
      status: 'Статус',
      cost: 'Стоимость',
      balance: 'Баланс',
      time: 'Время отправки',
    },
    logsStatus: {
      success: 'Отправлено',
      error: 'Не отправлено. Ошибка: ',
      balanceError: 'Не удалось получить баланс после этой операции',
    },
    logsFooter: {
      itemsPerPageAllText: 'все',
      itemsPerPageText: 'Показывать строк:'
    },
    general: {
      currency: 'UAH',
      balanceError: 'Не удалось получить баланс'
    },
  },
};
