/* eslint-disable  @typescript-eslint/no-explicit-any */
import Api from '@/api/Api';
import SubscribersFilter from '@/api/SubscribersFilter';
import { SubscriberCredentials } from '@/config/types';

const SUBSCRIBERS_URL = '/subscribers';
const SUBSCRIBER_URL = 'subscribers/:id';

export default class Subscribers extends SubscribersFilter {
  public static async getAllSubscribers(q?: string) {
    try {
      // @ts-ignore
      let response;
      if (q) {
        response = await Api.$instance.safeRequest(SUBSCRIBERS_URL, 'get', {
          params: {
            q
          }
        });
      } else {
        response = await Api.$instance.safeRequest(SUBSCRIBERS_URL, 'get');
      }
      // @ts-ignore
      const subscribers = response.data.data.map((subscriber: any) => this.filterIncluded(subscriber, response.data.included));

      return subscribers;
    } catch (e) {
      return null;
    }
  }

  public static async createSubscriber(credentials: SubscriberCredentials) {
    return await Api.$instance.safeRequest(SUBSCRIBERS_URL, 'post', credentials);
  }

  public static async updateSubscriber(credentials: SubscriberCredentials, id: number) {
    return await Api.$instance.safeRequest(Api.url(SUBSCRIBER_URL, {
      id,
    }), 'put', credentials);
  }

  public static async deleteSubscriber(id: number) {
    return await Api.$instance.safeRequest(Api.url(SUBSCRIBER_URL, {
      id,
    }), 'delete');
  }
}
