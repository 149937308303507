




























import { Component, Prop, Mixins } from 'vue-property-decorator';
import AccordionTabContent from '@/components/AccordionTabContent.vue';
import { Subscriber } from '@/config/types';
import Phone from '@/config/mixins/Phone';

@Component({
  components: {
    AccordionTabContent,
  },
})
export default class Accordion extends Mixins(Phone) {
  @Prop() protected subscribers!: Subscriber[];

  public activeTab: number | null = null

  public accordionKey = 0

  public setActiveTab(index: number | null) {
    this.activeTab = index;
  }

  public created() {
    this.$bus.$on('close-accordion-tab', () => {
      this.activeTab = null;
      this.changeAccordionKey();
    });
  }

  public formattedPhone(phone: string) {
    return this.formatPhone(phone);
  }

  public changeAccordionKey() {
    if (this.accordionKey === 0) {
      this.accordionKey = 1;

      return;
    }

    this.accordionKey = 0;
  }
}
