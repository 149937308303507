import Api from '@/api/Api';
import { LoginCredentials } from '@/config/types';

const LOGIN_URL = '/auth/login';
const LOGOUT_URL = '/auth/logout';

export default class Auth {
  public static async login(credentials: LoginCredentials) {
    return await Api.$instance.request(LOGIN_URL, 'post', credentials);
  }

  public static async logout() {
    return await Api.$instance.safeRequest(LOGOUT_URL, 'post');
  }
}
