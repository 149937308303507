import Vue from 'vue';
import VueRouter, { RouteConfig, RawLocation, Route } from 'vue-router';
import Home from '@/views/Home.vue';
import Logs from '@/views/Logs.vue';
import Login from '@/views/Login.vue';
import Archive from '@/views/Archive.vue';
import Auth from '@/middleware/Auth';
import middleware from '@/middleware';
import Middleware from '@/middleware/Middleware';
import Error500 from '@/views/Error500.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      middleware: Auth,
    },
  },
  {
    path: '/logs',
    name: 'logs',
    component: Logs,
    meta: {
      middleware: Auth,
    },
  },
  {
    path: '/archive',
    name: 'archive',
    component: Archive,
    meta: {
      middleware: Auth,
    },
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/500',
    name: 'error500',
    component: Error500
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

let isRedirected = false;

router.beforeEach(
  (to: Route, from: Route, next: (to?: RawLocation | false | ((vm: Vue) => any) | void) => void) => {
    middleware.forEach((middleware: Middleware) => {
      // @ts-ignore
      isRedirected = middleware.handle(to, from, next);
    });

    if (!isRedirected) {
      next();
    }
  },
);

export default router;
