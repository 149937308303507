







































































import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  mdiCircleEditOutline,
  mdiDelete,
} from '@mdi/js';
import ScheduleApi from '@/api/Schedules';
import { Schedule } from '@/config/types';
import EditScheduleModal from '@/components/modals/EditScheduleModal.vue';
import ConfirmationModal from '@/components/modals/ConfirmationModal.vue';

@Component({
  components: {
    EditScheduleModal,
    ConfirmationModal,
  },
})
export default class SchedulesTable extends Vue {
  @Prop() protected schedules!: Schedule[];

  @Prop() public tabIndex!: number;

  public isDeleteConfirmationOpen = false;

  public scheduleToDeleteId: number | null = null;

  public deleteIcon = mdiDelete;

  public editIcon = mdiCircleEditOutline;

  public async confirmDelete() {
    // @ts-ignore
    const response = await ScheduleApi.deleteSchedule(this.scheduleToDeleteId);

    if (response.status >= 300) {
      this.$bus.$emit('show-notification', {
        text: this.$t('snackbarNotifications.smthWentWrong'),
        color: 'error',
      });

      this.isDeleteConfirmationOpen = false;

      return;
    }

    this.$bus.$emit('schedule-deleted-with-btn');

    this.isDeleteConfirmationOpen = false;
  }

  public discardDelete() {
    this.isDeleteConfirmationOpen = false;
  }

  public openEditScheduleModal(schedule: Schedule) {
    this.$bus.$emit('open-edit-schedule-modal', parseInt(schedule.id));
  }

  public showDeleteScheduleConfirmationModal(id: number) {
    this.scheduleToDeleteId = id;

    this.$nextTick(() => {
      this.isDeleteConfirmationOpen = true;
    });
  }
}
