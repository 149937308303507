import Schedule from '@/models/Schedule';
import Model from '@/models/Model';

export default class Subscriber extends Model {
  public id!: number;

  public type!: string;

  public name?: string;

  public phone!: string;

  public schedules!: Schedule[];
}
