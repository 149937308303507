
















import { Component, Vue, Watch } from 'vue-property-decorator';
import Home from '@/views/Home.vue';
import SimpleNotification from '@/components/SimpleNotification.vue';
import AppHeader from '@/components/AppHeader.vue';
import Logs from '@/views/Logs.vue';
import Login from '@/views/Login.vue';
import Archive from '@/views/Archive.vue';
import Error500 from '@/views/Error500.vue';

@Component({
  components: {
    Home,
    SimpleNotification,
    AppHeader,
    Logs,
    Login,
    Archive,
    Error500,
  },
})
export default class App extends Vue {
  public isHeaderShown = false

  public created() {
    this.determineHeaderState();
  }

  public determineHeaderState() {
    if (this.$router.currentRoute.name === 'login' || this.$router.currentRoute.name === 'error500') {
      this.isHeaderShown = false;
    } else {
      this.isHeaderShown = true;
    }
  }

  @Watch('$route')
  public routeChanged() {
    this.determineHeaderState();
  }
}
