import I18nFix from '@/plugins/I18nFix.ts';
import Vue from 'vue';
import store from './store';
import vuetify from './plugins/vuetify';
// @ts-ignore
import VueSSE from 'vue-sse';
import '@/assets/sass/app.scss';
// @ts-ignore
import { VueMaskDirective } from 'v-mask';
import EventBus from 'vue-bus-ts';
import VueI18n from 'vue-i18n';
import langs from '@/lang';
import Auth from '@/helpers/Auth';
import router from './router';
import App from './App.vue';

Vue.directive('mask', VueMaskDirective);

Vue.use(VueI18n);
Vue.use(EventBus);
Vue.use(I18nFix);
Vue.use(VueSSE);

const bus = new EventBus.Bus();

const i18n = new VueI18n({
  locale: 'ru',
  messages: langs,
});

Vue.config.productionTip = false;

const accessToken = Auth.getAccessToken();

if (accessToken) {
  Auth.setAccessToken(accessToken);
}

new Vue({
  router,
  store,
  vuetify,
  bus,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
