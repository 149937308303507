






















import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class SimpleModal extends Vue {
  @Prop() public name!: string;

  public isOpen = false

  public created() {
    this.$bus.$on('open-simple-modal', this.openModal);
  }

  public openModal(modalName: string) {
    if (this.name === modalName) {
      this.isOpen = true;
    }
  }
}
