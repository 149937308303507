


































import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class ConfirmationModal extends Vue {
  @Prop() public isOpen!: boolean;

  @Prop() public title!: string;

  @Prop() public text!: string;

  @Prop() public confirmText?: string;
  public discardClosing() {
    this.$emit('discard-closing');
  }

  public confirmClosing() {
    this.$emit('confirm-closing');
  }
}
