import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Phone extends Vue {
  public phoneMask = '+38 (###) ### ## ##';
  public phonePlaceholder= '+38 (050) 234 56 78'

  public makePhoneValid(phone: string) {
    return phone.replace(/ /g, '').replace('(', '').replace(')', '');
  }

  public formatPhone(phone: string) {
    //+38 (068) 285 3319
    return `${phone.slice(0, 3)} (${phone.slice(3, 6)}) ${phone.slice(6, 9)} ${phone.slice(9, 11)} ${phone.slice(11)}`;
  }
}
