























































































































































































import {
  Component, Watch, Mixins, Prop,
} from 'vue-property-decorator';
import { required, max, regex } from 'vee-validate/dist/rules';
import {
  extend, ValidationObserver, ValidationProvider, setInteractionMode,
} from 'vee-validate';
// @ts-ignore
import { DateTime } from 'luxon';
import config from '@/config';
import DetectCyrillic from '@/config/mixins/DetectCyrillic';
import Time from '@/config/mixins/Time';
import Phone from '@/config/mixins/Phone';
import { ScheduleCredentials } from '@/config/types';
import ScheduleApi from '@/api/Schedules';
import ServerErrors from '@/config/mixins/ServerErrors';
import SimpleModal from '@/components/modals/SimpleModal.vue';
import ConfirmationModal from '@/components/modals/ConfirmationModal.vue';

setInteractionMode('eager');

extend('isAfter', {
  params: ['target'],
  // @ts-ignore
  validate(value, { target }) {
    return DateTime.fromISO(value) > DateTime.fromISO(target);
  },
  message: 'Дата окончания рассылки должна быть позднее даты начала',
});

extend('required', {
  ...required,
  message: 'Это поле должно быть заполнено',
});

extend('max', {
  ...max,
  message: 'Длинна сообщения должна быть не более {length} символов',
});

extend('regex', {
  ...regex,
  message: 'Неверный формат',
});

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    SimpleModal,
    ConfirmationModal,
  },
})
export default class NewScheduleForm extends Mixins(DetectCyrillic, Time, Phone, ServerErrors) {
  @Prop() public subscriberId!: number;

  @Prop() clearForm!: boolean;

  public isClearConfirmationOpen: boolean = false;

  public isFormLoading = false;

  public isAnyInputFilled = false;

  public time = '';

  public todayDate: string = this.getToday();

  public nextDay: string = this.getNextDay(this.todayDate);

  public minEndDate: string = this.nextDay;

  public startDatePicker: string = this.todayDate;

  public endDatePicker = this.nextDay;

  public messageMaxLength: number = config.smsParams.cyrillicMaxLength;

  public isFormCleaning = false;

  public scheduleCredentials: ScheduleCredentials = {
    start_date: '',
    end_date: '',
    repeat_frequency: '',
    hour: null,
    minute: null,
    message: '',
    subscriber_id: null,
  };

  public showClearConfirmationModal() {
    this.isClearConfirmationOpen = true;
  }

  public confirmClear() {
    this.clearAllInputs();

    this.isClearConfirmationOpen = false;
  }

  public discardClear() {
    this.isClearConfirmationOpen = false;
  }

  public addTimeToCredentials() {
    this.isAnyInputFilled = true;

    const splittedTime = this.splitTime(this.time);
    this.scheduleCredentials = { ...this.scheduleCredentials, ...splittedTime };
  }

  public async submit() {
    this.isFormLoading = true;
    // @ts-ignore
    const isValid = await this.$refs.observer.validate();

    if (!isValid) {
      this.isFormLoading = false;
    }

    this.scheduleCredentials.subscriber_id = this.subscriberId;

    this.addTimeToCredentials();

    const scheduleResponse = await ScheduleApi.createSchedule(this.scheduleCredentials);

    if (scheduleResponse.status >= 500) {
      return;
    }

    if (scheduleResponse.status >= 300) {
      this.parseServerErrors(scheduleResponse.data.errors, this.$refs.observer);

      this.isFormLoading = false;

      return;
    }

    this.isFormLoading = false;

    this.$bus.$emit('new-schedule-added');
  }

  public async closeFormModal() {
    if (!this.isAnyInputFilled) {
      this.$emit('close-form-modal');

      return;
    }

    this.$emit('show-close-confirmation');
  }

  public clearAllInputs() {
    for (const credential in this.scheduleCredentials) {
      // @ts-ignore
      this.scheduleCredentials[credential] = '';
    }

    this.isFormCleaning = true;

    this.time = '';
    this.minEndDate = this.nextDay;
    this.startDatePicker = this.todayDate;
    this.endDatePicker = this.nextDay;
    this.messageMaxLength = config.smsParams.cyrillicMaxLength;
    // @ts-ignore
    this.$refs.observer.reset();

    this.isAnyInputFilled = false;

    this.$nextTick(() => {
      this.isFormCleaning = false;
    })
  }

  public detectMessageMaxLength(msg: string) {
    this.isAnyInputFilled = true;

    if (!this.detectCyrillic(msg)) {
      this.messageMaxLength = config.smsParams.latinMaxLength;

      return;
    }

    this.messageMaxLength = config.smsParams.cyrillicMaxLength;
  }

  public showStartDatePicker() {
    this.$bus.$emit('open-simple-modal', `newScheduleStartDate subscriber${this.subscriberId}`);

    this.scheduleCredentials.start_date = this.startDatePicker;

    this.isAnyInputFilled = true;
  }

  public showEndDatePicker() {
    this.$bus.$emit('open-simple-modal', `newScheduleEndDate subscriber${this.subscriberId}`);

    this.scheduleCredentials.end_date = this.endDatePicker;

    this.isAnyInputFilled = true;
  }

  @Watch('endDatePicker')
  public endDateChanged() {
    if (this.isFormCleaning) {
      return;
    }

    this.scheduleCredentials.end_date = this.endDatePicker;

    this.minEndDate = this.getNextDay(this.startDatePicker);
  }

  @Watch('startDatePicker')
  public startDateChanged() {
    if (this.isFormCleaning) {
      return;
    }

    this.scheduleCredentials.start_date = this.startDatePicker;

    this.minEndDate = this.getNextDay(this.startDatePicker);
  }

  @Watch('clearForm')
  public clearFormPropChanged() {
    if (this.clearForm) {
      this.clearAllInputs();
    }
  }
}
