/* eslint-disable  @typescript-eslint/no-explicit-any */
export default abstract class Model {
  protected map: any = {};

  public static make(attributes: any[], payload?: any) {
    // @ts-ignore
    // We create a fresh instance of model (child only) and fill it with attributes.
    const model = (new this());

    model.setAttributes(attributes, payload);

    delete model.map;
    delete model.attributes;

    return model;
  }

  public setAttributes(attributes: any[], payload?: any) {
    if (this.map.extraAttributes) {
      for (const key in this.map.extraAttributes.attributes) {
        if (attributes[this.map.extraAttributes.path][this.map.extraAttributes.attributes[key]]) {
          // @ts-ignore
          this[key] = attributes[this.map.extraAttributes.path][this.map.extraAttributes.attributes[key]];
        }
      }
    }
    for (const alias in this.map) {
      if (attributes[this.map[alias]]) {
        // @ts-ignore
        this[alias] = attributes[this.map[alias]];
      }
    }

    for (const attribute in attributes) {
      if (!Object.values(this.map).includes(attribute)) {
        // @ts-ignore
        this[attribute] = attributes[attribute];
      }
    }

    if (payload) {
      // @ts-ignore
      this[payload.key] = payload.value;
    }

    return this;
  }
}
