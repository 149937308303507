/* eslint-disable  @typescript-eslint/no-explicit-any */
import Subscriber from '@/models/Subscriber';

export default class SubscribersFilter {
  public static filterIncluded(subscriber: any, included: any) {
    // @ts-ignore
    const payload = {
      key: 'schedules',
      value: [],
    };

    for (const subscriberSchedule of subscriber.relationships.schedules.data) {
      for (const schedule of included) {
        if (subscriberSchedule.id === schedule.id) {
          // @ts-ignore
          payload.value.push({ ...schedule.attributes, id: schedule.id, type: schedule.type });

          break;
        }
      }
    }

    // @ts-ignore
    return Subscriber.make({ ...subscriber, ...subscriber.attributes }, payload);
  }
}
