


































import { Component, Vue, Prop } from 'vue-property-decorator';
import NewScheduleForm from '@/components/forms/NewScheduleForm.vue';
import ConfirmationModal from '@/components/modals/ConfirmationModal.vue';

@Component({
  components: {
    NewScheduleForm,
    ConfirmationModal,
  },
})
export default class NewScheduleModal extends Vue {
  @Prop() public subscriberId!: number;

  public isOpen = false;

  public clearForm = false;

  public isCloseConfirmationOpen = false;

  public created() {
    this.$bus.$on('open-new-schedule-modal', this.openModal);
    this.$bus.$on('close-new-schedule-modal', this.closeModal);
  }

  public openModal(subscriberId: number) {
    if (this.subscriberId !== subscriberId) {
      return;
    }

    this.clearForm = true;

    this.$nextTick(() => {
      this.isOpen = true;
    })
  }

  public closeModal() {
    this.clearForm = false;

    this.isOpen = false;
  }

  public confirmClosing() {
    this.isCloseConfirmationOpen = false;
    this.closeModal();
  }

  public discardClosing() {
    this.isCloseConfirmationOpen = false;
  }

  public showCloseConfirmation() {
    this.isCloseConfirmationOpen = true;
  }
}
