







































































import {
  Component, Mixins, Prop,
} from 'vue-property-decorator';
import {required} from 'vee-validate/dist/rules';
import {
  extend, ValidationObserver, ValidationProvider, setInteractionMode,
} from 'vee-validate';
import Phone from '@/config/mixins/Phone';
import { LoginCredentials } from '@/config/types';
import AuthApi from '@/api/Auth';
import ServerErrors from '@/config/mixins/ServerErrors';
import Auth from '@/helpers/Auth';

setInteractionMode('eager');

extend('required', {
  ...required,
  message: 'Это поле должно быть заполнено',
});

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
  },
})
export default class LoginForm extends Mixins(Phone, ServerErrors) {
  @Prop() clearForm!: boolean;

  public isFormLoading = false;

  public generalErrorMessage: string = '';

  public phone = '';

  public loginCredentials: LoginCredentials = {
    phone: '',
    password: '',
  };

  public async submit() {
    this.isFormLoading = true;
    this.generalErrorMessage = '';
    // @ts-ignore
    const isValid = await this.$refs.observer.validate();

    if (!isValid) {
      this.isFormLoading = false;

      return;
    }

    this.loginCredentials.phone = this.makePhoneValid(this.phone);

    const response = await AuthApi.login(this.loginCredentials);

    if (response.status === 429) {
      this.generalErrorMessage = response.data.error.message;

      this.isFormLoading = false;

      return;
    }

    if (response.status >= 500) {
      this.isFormLoading = false;

      return;
    }

    if (response.status >= 300) {
      this.parseServerErrors(response.data.errors, this.$refs.observer);

      this.isFormLoading = false;

      return;
    }

    const token = response.data.accessToken;

    Auth.setAccessToken(token);

    this.isFormLoading = false;

    this.clearAllInputs();

    this.$nextTick(() => {
      this.$router.push({ name: 'home' });
    });
  }

  public clearAllInputs() {
    for (const credential in this.loginCredentials) {
      // @ts-ignore
      this.loginCredentials[credential] = '';
    }

    this.generalErrorMessage = '';

    this.phone = '';
    // @ts-ignore
    this.$refs.observer.reset();
  }
}
