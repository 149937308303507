/* eslint-disable  @typescript-eslint/no-explicit-any */

export default {
  isAuth(state: any) {
    return state.isAuth;
  },

  accessToken(state: any) {
    return state.accessToken;
  },
};
