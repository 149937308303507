import Api from '@/api/Api';
import store from '@/store';

export default class Auth {
  public static setAccessToken(token: string) {
    localStorage.setItem('accessToken', token);

    store.commit('auth/setAccessToken', token);
    store.commit('auth/setUserAuthState', true);

    Api.$instance.setAuthToken(token);
  }

  public static getAccessToken() {
    return localStorage.getItem('accessToken');
  }

  public static invalidateAccessToken() {
    localStorage.removeItem('accessToken');

    store.commit('auth/setAccessToken', '');
    store.commit('auth/setUserAuthState', false);

    Api.$instance.setAuthToken('');
  }
}
