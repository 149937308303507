/* eslint-disable  @typescript-eslint/no-explicit-any */
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class ServerErrors extends Vue {
  public parseServerErrors(errors: any[], observer: any) {
    const serverErrors = {};

    errors.forEach((error) => {
      if (error.param === 'minute' || error.param === 'hour') {
        // @ts-ignore
        serverErrors.time = [error.msg];
      }
      // @ts-ignore
      serverErrors[error.param] = [error.msg];
    });
    // @ts-ignore
    observer.setErrors(serverErrors);
  }
}
