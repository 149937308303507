import { RawLocation, Route } from 'vue-router';
import Middleware from '@/middleware/Middleware.ts';
import store from '@/store';
import { Vue } from 'vue/types/vue';

export default class Auth extends Middleware {
  public handle(to: Route, from: Route, next: (to?: RawLocation | false | ((vm: Vue) => any) | void) => void): boolean {
    if (this.matched(to, Auth) && !store.getters['auth/isAuth']) {
      next({
        name: 'login',
      });

      return true;
    }

    return false;
  }
}
