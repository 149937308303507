













/* eslint-disable  @typescript-eslint/no-empty-function */
import { Component, Vue } from 'vue-property-decorator';
import { TranslateResult, LocaleMessages } from 'vue-i18n';
import { SnackbarConfig } from '@/config/types';

@Component
export default class SimpleNotification extends Vue {
  public snackbarOn = false;

  public snackbarText: string | LocaleMessages | TranslateResult = '';

  public color = '';

  public snackBarTimeout = 5000;

  public created() {
    this.$bus.$on('show-notification', this.showSnackbar);
  }

  public showSnackbar(config: SnackbarConfig) {
    this.snackbarText = config.text;

    this.color = config.color;

    this.$nextTick(() => {
      this.snackbarOn = true;
    });
  }
}
