








































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import SchedulesTable from '@/components/SchedulesTable.vue';
import { Subscriber } from '@/config/types';
import NewScheduleModal from '@/components/modals/NewScheduleModal.vue';
import EditSubscriberModal from '@/components/modals/EditSubscriberModal.vue';

@Component({
  components: {
    SchedulesTable,
    NewScheduleModal,
    EditSubscriberModal,
  },
})
export default class AccordionTabContent extends Vue {
  @Prop() protected subscriber!: Subscriber;

  @Prop() public tabIndex!: number;

  public subscriberId: number | null = null;

  public openNewScheduleModal() {
    this.$bus.$emit('open-new-schedule-modal', this.subscriberId);
  }

  public closeNewScheduleModal() {
    this.$bus.$emit('open-new-schedule-modal', this.subscriberId);
  }

  public openEditSubscriberModal() {
    this.$bus.$emit('open-edit-subscriber-modal', this.subscriberId);
  }

  public created() {
    this.subscriberId = parseInt(this.subscriber.id);
  }

  @Watch('subscriber', {deep: true})
  public onSubscriberChange() {
    this.subscriberId = parseInt(this.subscriber.id);
  }
}
