import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import { RootState } from '@/store/types';

// Modules
import modules from '@/store/modules';

Vue.use(Vuex);

const store: StoreOptions<RootState> = {
  modules,
};

export default new Vuex.Store<RootState>(store);
