/* eslint-disable  @typescript-eslint/no-explicit-any */
import Log from '@/models/Log';

export default class LogsFilter {
  public static filterIncluded(log: any, included: any) {
    // @ts-ignore
    const payload = {
      key: 'extraData',
      value: {},
    };

    const subscriberId = log.relationships.subscriber.data.id;
    const scheduleId = log.relationships.schedule.data.id;

    for (const item of included) {
      if (item.type === 'schedule' && item.id === scheduleId) {
        const schedule = {...item.attributes}
        // @ts-ignore
        payload.value.schedule = schedule;
      }

      if (item.type === 'subscriber' && item.id === subscriberId) {
        const subscriber = {...item.attributes}
        // @ts-ignore
        payload.value.subscriber = subscriber;
      }
    }
    // @ts-ignore
    return Log.make({ ...log, ...log.attributes }, payload);
  }
}
