






























import { Component, Vue, Watch } from 'vue-property-decorator';

@Component
export default class SearchForm extends Vue {
  public searchQuery: string = '';

  public timer: any = null;

  public created() {
    if (this.$route.query.q) {
      // @ts-ignore
      this.searchQuery = this.$route.query.q;
    }
  }

  public clearSearchQuery () {
    this.searchQuery = '';
    // @ts-ignore
    this.$router.push({'query': null});
  }

  public handleSearchInput () {
        if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
    }

    this.timer = setTimeout(() => {
        this.$router.push({query: { 'q' : this.searchQuery}}).catch(err => {});
    }, 500);
  }
}
