var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('form',{staticClass:"mt-20 mb-20",attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return passes(_vm.submit)}}},[_c('ValidationProvider',{attrs:{"rules":("required|max:" + _vm.messageMaxLength),"name":"message","vid":"message"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"tabindex":"1","error-messages":errors,"label":_vm.$t('scheduleForm.messageLabel'),"counter":_vm.messageMaxLength,"maxlength":_vm.messageMaxLength,"required":"","auto-grow":true,"rows":"1","disabled":_vm.isFormLoading},on:{"input":_vm.detectMessageMaxLength},model:{value:(_vm.scheduleCredentials.message),callback:function ($$v) {_vm.$set(_vm.scheduleCredentials, "message", $$v)},expression:"scheduleCredentials.message"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"startDate","rules":"required","vid":"start_date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"tabindex":"2","error-messages":errors,"label":_vm.$t('scheduleForm.startDateLabel'),"required":"","disabled":_vm.isFormLoading},on:{"focus":_vm.showStartDatePicker},model:{value:(_vm.scheduleCredentials.start_date),callback:function ($$v) {_vm.$set(_vm.scheduleCredentials, "start_date", $$v)},expression:"scheduleCredentials.start_date"}}),_c('simple-modal',{attrs:{"name":("editScheduleStartDate " + (_vm.schedule.id))}},[_c('v-date-picker',{attrs:{"landscape":true,"full-width":true,"min":_vm.todayDate,"first-day-of-week":1,"locale":"ru-ru"},model:{value:(_vm.startDatePicker),callback:function ($$v) {_vm.startDatePicker=$$v},expression:"startDatePicker"}})],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"endDate","rules":"required|isAfter:@start_date","vid":"end_date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"tabindex":"3","error-messages":errors,"label":_vm.$t('scheduleForm.endDateLabel'),"required":"","disabled":_vm.isFormLoading},on:{"focus":_vm.showEndDatePicker},model:{value:(_vm.scheduleCredentials.end_date),callback:function ($$v) {_vm.$set(_vm.scheduleCredentials, "end_date", $$v)},expression:"scheduleCredentials.end_date"}}),_c('simple-modal',{attrs:{"name":("editScheduleEndDate " + (_vm.schedule.id))}},[_c('v-date-picker',{attrs:{"first-day-of-week":1,"locale":"ru-ru","landscape":true,"full-width":true,"min":_vm.minEndDate},model:{value:(_vm.endDatePicker),callback:function ($$v) {_vm.endDatePicker=$$v},expression:"endDatePicker"}})],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"time","rules":{required: true, regex: _vm.timeRegex},"vid":"time"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.timeMask),expression:"timeMask"}],attrs:{"tabindex":"4","placeholder":_vm.timePlaceholder,"error-messages":errors,"label":_vm.$t('scheduleForm.timeLabel'),"required":"","disabled":_vm.isFormLoading},model:{value:(_vm.time),callback:function ($$v) {_vm.time=$$v},expression:"time"}})]}}],null,true)}),_c('div',{staticClass:"repeat-frequency-input-wrapper"},[_c('ValidationProvider',{attrs:{"name":"repeatFrequency","rules":"required|regex:^((\\d)m\\s?)?((\\d)w\\s?)?((\\d)d)?$","vid":"repeat_frequency"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"repeat-frequency-input",attrs:{"tabindex":"5","error-messages":errors,"label":_vm.$t('scheduleForm.repeatFrequencyLabel'),"placeholder":"1w 2d","required":"","disabled":_vm.isFormLoading},model:{value:(_vm.scheduleCredentials.repeat_frequency),callback:function ($$v) {_vm.$set(_vm.scheduleCredentials, "repeat_frequency", $$v)},expression:"scheduleCredentials.repeat_frequency"}})]}}],null,true)}),_c('div',{staticClass:"repeat_frequency_tooltip"},[_vm._v(" "+_vm._s(_vm.$t('scheduleForm.repeatFrequencyTooltip'))+" ")])],1),_c('div',{staticClass:"form-card-btns-container"},[_c('v-btn',{staticClass:"form-card-btn",attrs:{"tabindex":"7","large":"","color":"primary"},on:{"click":_vm.closeFormModal}},[_vm._v(" "+_vm._s(_vm.$t('btns.close'))+" ")]),_c('v-btn',{staticClass:"form-card-btn",attrs:{"tabindex":"8","large":"","color":"warning","disabled":!_vm.isAnyInputChanged},on:{"click":_vm.showClearConfirmationModal}},[_vm._v(" "+_vm._s(_vm.$t('btns.discardChanges'))+" ")]),_c('v-btn',{staticClass:"form-card-btn form-card-btn-right",attrs:{"type":"submit","large":"","color":"success","loading":_vm.isFormLoading,"disabled":!_vm.isAnyInputChanged,"tabindex":"6"}},[_vm._v(" "+_vm._s(_vm.$t('btns.save'))+" ")])],1)],1)]}}])}),_c('confirmation-modal',{attrs:{"title":_vm.$t('confirmationModals.resetInputsTitle'),"text":_vm.$t('confirmationModals.resetInputsText'),"isOpen":_vm.isClearConfirmationOpen},on:{"confirm-closing":_vm.confirmClear,"discard-closing":_vm.discardClear}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }