


































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import EditScheduleForm from '@/components/forms/EditScheduleForm.vue';
import ConfirmationModal from '@/components/modals/ConfirmationModal.vue';
import { Schedule } from '@/config/types';

@Component({
  components: {
    EditScheduleForm,
    ConfirmationModal,
  },
})
export default class EditScheduleModal extends Vue {
  @Prop() public schedule!: Schedule;

  public isOpen = false;

  public clearForm = false;

  public isCloseConfirmationOpen = false;

  public scheduleId: number | null = null;

  public created() {
    this.scheduleId = parseInt(this.schedule.id);

    this.$bus.$on('open-edit-schedule-modal', this.openModal);
    this.$bus.$on('close-edit-schedule-modal', this.closeModal);
  }

  public openModal(scheduleId: number) {
    if (this.scheduleId !== scheduleId) {
      return;
    }

    this.clearForm = true;

    this.$nextTick(() => {
      this.isOpen = true;
    })
  }

  public closeModal() {
    this.clearForm = false;

    this.isOpen = false;
  }

  public confirmClosing() {
    this.isCloseConfirmationOpen = false;

    this.closeModal();
  }

  public discardClosing() {
    this.isCloseConfirmationOpen = false;
  }

  public showCloseConfirmation() {
    this.isCloseConfirmationOpen = true;
  }

  @Watch('schedule', {deep: true})
  public onScheduleChange() {
    this.scheduleId = parseInt(this.schedule.id);
  }
}
