import { Route } from 'vue-router';
import wrapArray from '@/helpers/wrapArray';

export default abstract class Middleware {
  public abstract handle(to: Route, from: Route, next: () => void): void;

  protected matched(to: Route, middleware: object) {
    return to.matched.some((record) => record.meta.middleware && wrapArray(record.meta.middleware).includes(middleware));
  }
}
