


































import { Component, Vue, Watch } from 'vue-property-decorator';
import Accordion from '@/components/Accordion.vue';
import Loader from '@/components/Loader.vue';
import { Subscriber } from '@/config/types';
import NewSubscriberModal from '@/components/modals/NewSubscriberModal.vue';
import AppHeader from '@/components/AppHeader.vue';
import SearchForm from '@/components/forms/SearchForm.vue';

@Component({
  components: {
    Accordion,
    Loader,
    NewSubscriberModal,
    AppHeader,
    SearchForm,
  },
})
export default class Home extends Vue {
  public subscribersLoading = true;

  public subscribers: Subscriber[] = [];

  public subscribersLoadingFailed = false;

  public searchQuery: (string | null)[] | string = '';

  public isSubscribersSearch = false;

  public newSubscriberModalOpened = false;

  public async created() {
    await this.startSearch();

    this.$bus.$on('subscriber-updated', this.updateSubscriber);

    this.$bus.$on('schedule-updated', this.updateSchedule);

    this.$bus.$on('new-schedule-added', this.addNewSchedule);

    this.$bus.$on('subscriber-deleted', this.deleteSubscriber);

    this.$bus.$on('new-subscriber-added', this.addNewSubscriber);

    this.$bus.$on('schedule-deleted-with-btn', this.deleteScheduleWithBtn);
  }

  public openNewSubscriberModal() {
    this.newSubscriberModalOpened = true;
  }

  public closeNewSubscriberModal() {
    this.newSubscriberModalOpened = false;
  }

  public async updateSchedule() {
    await this.loadSubscribers();

    this.$bus.$emit('close-edit-schedule-modal');

    this.$bus.$emit('show-notification', {
      text: this.$t('snackbarNotifications.scheduleUpdated'),
      color: 'success',
    });
  }

  public async deleteScheduleWithBtn() {
    await this.loadSubscribers();

    this.$bus.$emit('show-notification', {
      text: this.$t('snackbarNotifications.scheduleDeleted'),
      color: 'success',
    });
  }

  public async deleteSubscriber() {
    await this.loadSubscribers();

    this.$bus.$emit('close-edit-subscriber-modal', true);

    this.$bus.$emit('close-accordion-tab');

    this.$bus.$emit('show-notification', {
      text: this.$t('snackbarNotifications.subscriberDeleted'),
      color: 'success',
    });
  }

  public async addNewSchedule() {
    await this.loadSubscribers();

    this.$bus.$emit('close-new-schedule-modal');

    this.$bus.$emit('show-notification', {
      text: this.$t('snackbarNotifications.newScheduleAdded'),
      color: 'success',
    });
  }

  public async addNewSubscriber() {
    await this.loadSubscribers();

    this.$bus.$emit('close-new-subscriber-form-modal');

    this.$bus.$emit('show-notification', {
      text: this.$t('snackbarNotifications.newSubscriberAdded'),
      color: 'success',
    });
  }

  public async updateSubscriber() {
    await this.loadSubscribers();

    this.$bus.$emit('close-edit-subscriber-modal');

    this.$bus.$emit('show-notification', {
      text: this.$t('snackbarNotifications.subscriberUpdated'),
      color: 'success',
    });
  }

  public async startSearch () {
    if (this.$route.query.q && this.$route.query.q.length > 2) {
      this.searchQuery = this.$route.query.q;
    } else {
      this.searchQuery = '';
    }

    this.$nextTick(async () => {
      await this.loadSubscribers();
    })
  }

  public async loadSubscribers() {
    if (this.searchQuery) {
      this.isSubscribersSearch = true;
    } else {
      this.isSubscribersSearch = false;
    }

    this.subscribersLoadingFailed = false;

    await this.$store.dispatch('subscribers/loadSubscribers', this.searchQuery);

    const subscribers = this.$store.getters['subscribers/subscribers'];

    if (subscribers) {
      this.subscribers = subscribers;

      this.subscribersLoading = false;

      this.$bus.$emit('subscribers-loaded');

      return;
    }

    this.subscribersLoadingFailed = true;
    this.subscribersLoading = false;

    this.$bus.$emit('subscribers-loaded');

    this.$bus.$emit('show-notification', {
      text: this.$t('snackbarNotifications.smthWentWrong'),
      color: 'error',
    });
  }

  @Watch('$route.query')
  public routeQueryChanged() {
    this.startSearch();
  }
}
