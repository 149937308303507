



















































































































































































import {
  Component, Watch, Mixins, Prop,
} from 'vue-property-decorator';
import { required, max, regex } from 'vee-validate/dist/rules';
import {
  extend, ValidationObserver, ValidationProvider, setInteractionMode,
} from 'vee-validate';
// @ts-ignore
import { DateTime } from 'luxon';
import config from '@/config';
import ConfirmationModal from '@/components/modals/ConfirmationModal.vue';
import DetectCyrillic from '@/config/mixins/DetectCyrillic';
import Time from '@/config/mixins/Time';
import Phone from '@/config/mixins/Phone';
import { ScheduleCredentials, Schedule } from '@/config/types';
import ScheduleApi from '@/api/Schedules';
import ServerErrors from '@/config/mixins/ServerErrors';
import SimpleModal from '@/components/modals/SimpleModal.vue';

setInteractionMode('eager');

extend('isAfter', {
  params: ['target'],
  // @ts-ignore
  validate(value, { target }) {
    return DateTime.fromISO(value) > DateTime.fromISO(target);
  },
  message: 'Дата окончания рассылки должна быть позднее даты начала',
});

extend('required', {
  ...required,
  message: 'Это поле должно быть заполнено',
});

extend('max', {
  ...max,
  message: 'Длинна сообщения должна быть не более {length} символов',
});

extend('regex', {
  ...regex,
  message: 'Неверный формат',
});

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    ConfirmationModal,
    SimpleModal,
  },
})
export default class EditScheduleForm extends Mixins(DetectCyrillic, Time, Phone, ServerErrors) {
  @Prop() public schedule!: Schedule;

  @Prop() clearForm!: boolean;

  public isFormLoading = false;

  public isAnyInputChanged = false;

  public isClearConfirmationOpen: boolean = false;

  public isDeleteConfirmationOpen = false;

  public time = this.joinTime(this.schedule.hour, this.schedule.minute);

  public todayDate: string = this.getToday();

  public startDatePicker: string = this.schedule.start_date;

  public endDatePicker: string = this.schedule.end_date;

  public minEndDate: string = this.getNextDay(this.schedule.start_date);

  public messageMaxLength: number = config.smsParams.cyrillicMaxLength;

  public scheduleCredentials: ScheduleCredentials = {
    start_date: this.schedule.start_date,
    end_date: this.schedule.end_date,
    repeat_frequency: this.schedule.repeat_frequency,
    hour: this.schedule.hour,
    minute: this.schedule.minute,
    message: this.schedule.message,
    subscriber_id: this.schedule.subscriber_id,
  };

  public showClearConfirmationModal() {
    this.isClearConfirmationOpen = true;
  }

  public confirmClear() {
    this.resetAllInputs();

    this.isClearConfirmationOpen = false;
  }

  public discardClear() {
    this.isClearConfirmationOpen = false;
  }

  public created() {
    this.detectMessageMaxLength(this.schedule.message);
  }

  public addTimeToCredentials() {
    this.isAnyInputChanged = true;

    const splittedTime = this.splitTime(this.time);
    this.scheduleCredentials = { ...this.scheduleCredentials, ...splittedTime };
  }

  public async submit() {
    this.isFormLoading = true;
    // @ts-ignore
    const isValid = await this.$refs.observer.validate();

    if (!isValid) {
      this.isFormLoading = false;
    }

    this.addTimeToCredentials();

    const scheduleResponse = await ScheduleApi.updateSchedule(this.scheduleCredentials, parseInt(this.schedule.id));

    if (scheduleResponse.status >= 500) {
      return;
    }

    if (scheduleResponse.status >= 300) {
      this.parseServerErrors(scheduleResponse.data.errors, this.$refs.observer);

      this.isFormLoading = false;

      return;
    }

    this.isFormLoading = false;

    this.$bus.$emit('schedule-updated');
  }

  public async closeFormModal() {
    if (!this.isAnyInputChanged) {
      this.$emit('close-form-modal');

      return;
    }

    this.$emit('show-close-confirmation');
  }

  public resetAllInputs() {
    this.scheduleCredentials = {
      start_date: this.schedule.start_date,
      end_date: this.schedule.end_date,
      repeat_frequency: this.schedule.repeat_frequency,
      hour: this.schedule.hour,
      minute: this.schedule.minute,
      message: this.schedule.message,
      subscriber_id: this.schedule.subscriber_id,
    };

    this.time = this.joinTime(this.schedule.hour, this.schedule.minute);
    this.startDatePicker = this.schedule.start_date;
    this.endDatePicker = this.schedule.end_date;
    // @ts-ignore
    this.$refs.observer.reset();
    this.isAnyInputChanged = false;
  }

  public detectMessageMaxLength(msg: string) {
    if (!this.detectCyrillic(msg)) {
      this.messageMaxLength = config.smsParams.latinMaxLength;

      return;
    }

    this.messageMaxLength = config.smsParams.cyrillicMaxLength;
  }

  public showStartDatePicker() {
    this.$bus.$emit('open-simple-modal', `editScheduleStartDate ${this.schedule.id}`);

    this.scheduleCredentials.start_date = this.startDatePicker;
  }

  public showEndDatePicker() {
    this.$bus.$emit('open-simple-modal', `editScheduleEndDate ${this.schedule.id}`);

    this.scheduleCredentials.end_date = this.endDatePicker;
  }

  public checkIfAnyInputChanged() {
    for (const credential of Object.keys(this.scheduleCredentials)) {
      // @ts-ignore
      if (this.scheduleCredentials[credential] !== this.schedule[credential]) {
        this.isAnyInputChanged = true;

        return;
      }
    }

    if (this.time !== this.joinTime(this.schedule.hour, this.schedule.minute)) {
      this.isAnyInputChanged = true;

      return;
    }


    this.isAnyInputChanged = false;
  }

  @Watch('endDatePicker')
  public endDateChanged() {
    this.scheduleCredentials.end_date = this.endDatePicker;
  }

  @Watch('startDatePicker')
  public startDateChanged() {
    this.scheduleCredentials.start_date = this.startDatePicker;

    this.minEndDate = this.getNextDay(this.startDatePicker);
  }

  @Watch('scheduleCredentials', {deep: true})
  public onScheduleCredentialsChange() {
    this.checkIfAnyInputChanged();
  }

  @Watch('time')
  public onTimeChange() {
    this.checkIfAnyInputChanged();
  }

  @Watch('clearForm')
  public clearFormPropChanged() {
    if (this.clearForm) {
      this.resetAllInputs();
    }
  }
}
