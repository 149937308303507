













































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import EditSubscriberForm from '@/components/forms/EditSubscriberForm.vue';
import ConfirmationModal from '@/components/modals/ConfirmationModal.vue';
import { Subscriber } from '@/config/types';

@Component({
  components: {
    EditSubscriberForm,
    ConfirmationModal,
  },
})
export default class EditSubscriberModal extends Vue {
  @Prop() public subscriber!: Subscriber;

  public isOpen = false;

  public clearForm = false;

  public isCloseConfirmationOpen = false;

  public subscriberId: number | null = null;

  public subscriberName: string | null | undefined = null;

  public subscriberPhone: string | null = null;

  public created() {
    this.setFormData();

    this.$bus.$on('open-edit-subscriber-modal', this.openModal);
    this.$bus.$on('close-edit-subscriber-modal', this.closeModal);
  }

  public openModal(subscriberId: number) {
    if (this.subscriberId !== subscriberId) {
      return;
    }
    this.clearForm = true;

    this.$nextTick(()=> {
      this.isOpen = true;
    })
  }

  public closeModal() {
    this.clearForm = false;

    this.isOpen = false;
  }

  public confirmClosing() {
    this.isCloseConfirmationOpen = false;
    this.closeModal();
  }

  public discardClosing() {
    this.isCloseConfirmationOpen = false;
  }

  public showCloseConfirmation() {
    this.isCloseConfirmationOpen = true;
  }

  public setFormData() {
    this.subscriberId = parseInt(this.subscriber.id);
    this.subscriberName = this.subscriber.name;
    this.subscriberPhone = this.subscriber.phone;
  }

  @Watch('subscriber', {deep: true})
  public onSubscriberChange() {
    this.setFormData();
  }
}
