import { Component, Vue } from 'vue-property-decorator';
import { DateTime } from 'luxon';

@Component
export default class Time extends Vue {
  public timeMask: string = '##:##';
  public timePlaceholder: string = '13:00';
  public timeRegex: string = `^([0-1][0-9]|2[0-3])\\:[0-5][0-9]$`

  public getToday() {
    return DateTime.local().toString().substr(0, 10);
  }

  public getNextDay(today: string) {
    return DateTime.fromISO(today).plus({ days: 1 }).toISODate();
  }

  public splitTime(time: string) {
    const timeArr = time.split(':')
    return {
      hour: parseInt(timeArr[0], 10),
      minute: parseInt(timeArr[1], 10),
    };
  }

  public joinTime(hour: number, minute: number) {
    let hourStr = hour.toString();
    let minuteStr = minute.toString();

    if (hourStr.length < 2) {
      hourStr = `0${hour}`;
    }

    if (minuteStr.length < 2) {
      minuteStr = `0${minute}`;
    }

    return `${hourStr}:${minuteStr}`;
  }
}
