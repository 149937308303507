/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import { Payload } from 'vuex';

export default {
  setSubscribers(state: any, payload: Payload) {
    state.subscribers = payload;
    state.loading = false;
  },
};
