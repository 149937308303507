import Api from '@/api/Api';
import { ScheduleCredentials } from '@/config/types';

const SCHEDULES_URL = '/schedules';
const SCHEDULE_URL = 'schedules/:id';

export default class Schedules {
  public static async createSchedule(credentials: ScheduleCredentials) {
    return await Api.$instance.safeRequest(SCHEDULES_URL, 'post', credentials);
  }

  public static async updateSchedule(credentials: ScheduleCredentials, id: number) {
    return await Api.$instance.safeRequest(Api.url(SCHEDULE_URL, {
      id,
    }), 'put', credentials);
  }

  public static async deleteSchedule(id: number) {
    return await Api.$instance.safeRequest(Api.url(SCHEDULE_URL, {
      id,
    }), 'delete');
  }
}
