/* eslint-disable  prefer-rest-params */
import Vue from 'vue';

export default function I18nFix() {
  const $i18nt = Vue.prototype.$t;
  Vue.prototype.$i18nt = $i18nt;
  Vue.prototype.$t = function () {
    if (this.$i18n) {
      return $i18nt.apply(this, arguments);
    }
    return $i18nt.apply(this.$root, arguments);
  };
}
