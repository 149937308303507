import camelCase from 'lodash/camelCase';

// Storing in variable a context with all files in this folder
// ending with `.ts`.
const requireModule = require.context('.', true, /\.ts$/);
const modules = {};

requireModule.keys().forEach((fileName: string) => {
  if (fileName === './index.ts') {
    return;
  }

  // filter fullstops and extension
  // and return a camel-case name for the file
  const modulePattern = /\.\/([A-z0-9_]+)\/index\.ts/g;
  const matches = modulePattern.exec(fileName);

  if (!matches || !matches[1]) {
    return;
  }

  const moduleName = camelCase(matches[1]);

  // @ts-ignore
  modules[moduleName] = {
    namespaced: true,
    ...requireModule(fileName).default,
  };
});

export default modules;
