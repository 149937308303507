








































































































import { Component, Vue} from 'vue-property-decorator';

@Component
export default class Error500 extends Vue {
}

