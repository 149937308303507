








import { Component, Vue } from 'vue-property-decorator';
import LoginForm from '@/components/forms/LoginForm.vue';

@Component({
  components: {
    LoginForm,
  },
})
export default class Login extends Vue {

}
